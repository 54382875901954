import React, { useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../../store';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import { CsvExportModule } from '@ag-grid-community/csv-export';
import { CellClassParams, ColDef } from '@ag-grid-community/core';
import { AgGridReact } from '@ag-grid-community/react';
import Button from 'react-bootstrap/Button';

import { Consultant, ConsultantExpanded } from '../../model/Consultant';
import {
    AG_GRID_REACT_DEFAULT_PROPS,
    COLUMN_DEFAULTS,
    DATE_FILTER_PARAMS,
    formatIsoDateString,
    hoursFormatter,
    isoDateCellFormatter,
    percentageFormatter,
} from '../../common';
import { CsvExportButton } from '../common/CsvExportButton';
import { generateFilterFunction } from '../locations/LocationUtils';
import { LocalDate } from '@js-joda/core';
import { IncludeFormerEmployeesSelector, useIncludeFormerEmployeesState } from './IncludeFormerEmployeesSelector';
import { generateGridStatePersistenceInstaller } from '../../common/gridState';
import { useCurrentLocations } from '../../customHooks/currentLocations';
import { StatusLine, statusRendererSelector, useCountStatus } from '../common/status';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { TableContentOptions } from '../common/TableContentOptions';
import { DeleteConsultantModal } from './DeleteConsultantModal';

import { RootState } from '../../../store/setupStore';
import { useConsultantsExpanded } from '../../customHooks/consultants';

function isConsultantActive(consultant: ConsultantExpanded): boolean {
    return !consultant.hasLeftAt(LocalDate.now());
}

const columnDefs: ColDef[] = [
    {
        headerName: 'Vorname',
        field: 'firstName',
        width: 160,
        cellRendererSelector: statusRendererSelector('Consultant', 'Consultants'),
        colSpan: (params) => (params.data.isStatus ? columnDefs.length : 1),
    },
    { headerName: 'Nachname', field: 'lastName', width: 160 },
    {
        headerName: 'Abrechenbarkeit',
        field: 'billability',
        filter: 'agNumberColumnFilter',
        valueFormatter: percentageFormatter,
        width: 160,
    },
    {
        headerName: 'Wochenarbeitszeit',
        field: 'workload',
        filter: 'agNumberColumnFilter',
        valueFormatter: hoursFormatter,
        width: 160,
    },
    { headerName: 'Skills', field: 'skills', flex: 1, hide: true },
    {
        headerName: 'Anstellungsbeginn',
        field: 'start',
        filter: 'agDateColumnFilter',
        filterParams: DATE_FILTER_PARAMS,
        width: 180,
        getQuickFilterText: (params) => formatIsoDateString(params.data.start),
        valueFormatter: isoDateCellFormatter,
    },
    {
        headerName: 'Anstellungsende',
        field: 'end',
        filter: 'agDateColumnFilter',
        filterParams: DATE_FILTER_PARAMS,
        width: 180,
        getQuickFilterText: (params) => formatIsoDateString(params.data.end),
        valueFormatter: isoDateCellFormatter,
    },
    { headerName: 'Standort', field: 'location.name' },
    {
        headerName: 'Reisebereitschaft',
        field: 'travelWillingness',
        filter: 'agNumberColumnFilter',
        valueFormatter: percentageFormatter,
    },
    {
        headerName: 'Stichwörter',
        field: 'keywords',
    },
    {
        headerName: 'Aktion',
        cellRenderer: ActionButtons,
        width: 150,
        suppressNavigable: true,
        filter: false,
        sortable: false,
        flex: 0,
    },
];

const installGridStatePersister = generateGridStatePersistenceInstaller();

export function ConsultantsOverview(): JSX.Element {
    const { pathname } = useLocation();
    const navigate = useNavigate();
    const agGrid = useRef<AgGridReact>(null);
    const consultants = useConsultantsExpanded();
    const [includeFormerEmployees, setIncludeFormerEmployees] = useIncludeFormerEmployeesState();
    const currentLocations = useCurrentLocations();
    const [statusData, updateStatusData] = useCountStatus(agGrid);
    const [deleteConsultant, setDeleteConsultant] = useState<Consultant>();

    const filteredConsultants = useMemo(() => {
        if (consultants) {
            const filterConsultantByLocationFunction = generateFilterFunction(currentLocations);
            const locationFilteredConsultants = consultants.filter(filterConsultantByLocationFunction);
            // By default ehemalige Consultants are included
            return includeFormerEmployees ? locationFilteredConsultants : locationFilteredConsultants.filter(isConsultantActive);
        }
        // show loading overlay
        return undefined;
    }, [consultants, currentLocations, includeFormerEmployees, setIncludeFormerEmployees]);

    const quickFilterText = useAppSelector((state: RootState) => state.filters.quickFilterText);

    const handleModalClose = (): void => {
        setDeleteConsultant(undefined);
    };

    return (
        <div className="grid-wrapper">
            <TableContentOptions
                creationButton={{ to: `${pathname}/new`, label: 'Neuer Consultant' }}
                quickFilterField
                exportButton={
                    <CsvExportButton api={agGrid?.current?.api} title="Angezeigte Consultants als CSV-Datei exportieren" />
                }
            >
                <IncludeFormerEmployeesSelector
                    callback={() => setIncludeFormerEmployees(!includeFormerEmployees)}
                    checked={includeFormerEmployees}
                />
            </TableContentOptions>
            <div className="grid-body ag-theme-balham">
                <AgGridReact
                    {...AG_GRID_REACT_DEFAULT_PROPS}
                    ref={agGrid}
                    context={{ setDeleteConsultant }}
                    defaultColDef={{ ...COLUMN_DEFAULTS, flex: 1 }}
                    columnDefs={columnDefs}
                    rowData={filteredConsultants}
                    modules={[ClientSideRowModelModule, CsvExportModule]}
                    onRowDoubleClicked={(ev) => ev.data.isStatus || navigate(`${pathname}/${ev.data.id}`)}
                    quickFilterText={quickFilterText}
                    onGridReady={installGridStatePersister}
                    onRowDataUpdated={updateStatusData}
                    onFilterChanged={updateStatusData}
                    components={{ status: StatusLine }}
                    pinnedBottomRowData={statusData}
                />
            </div>
            <DeleteConsultantModal consultantToDelete={deleteConsultant} onClose={handleModalClose} />
        </div>
    );
}

function ActionButtons(props: CellClassParams): JSX.Element {
    const { pathname } = useLocation();
    const consultant: Consultant = props.data;

    return (
        <>
            <Link to={`${pathname}/${consultant.id}`}>
                <Button variant="link" title="Consultant bearbeiten">
                    <span className="oi oi-pencil" />
                </Button>
            </Link>
            <Button variant="link" title="Consultant löschen" onClick={() => props.context.setDeleteConsultant(consultant)}>
                <span className="oi oi-trash" />
            </Button>
            <Link to={`/assignments/new?consultant=${consultant.id}`} state={{ returnTo: pathname }}>
                <Button variant="link" title="Consultant zuweisen">
                    <span className="oi im-person_add" />
                </Button>
            </Link>
        </>
    );
}
