import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { UserSettings } from '../../app/model/User'; // adjust the import path if necessary

interface UserState {
    isLoading: boolean;
    userSettings: UserSettings;
}

export enum ColorSchemeValue {
    SHADES_OF_GREEN = 'shadesOfGreen',
    AMPELFARBEN = 'ampelfarben',
}

export type BooleanSetting = 'true' | 'false';

const initialState: UserState = {
    isLoading: true,
    userSettings: {
        'last-locations': ['*'],
        'color-scheme': ColorSchemeValue.SHADES_OF_GREEN,
        'show-workload': 'false',
    },
};

const userSlice = createSlice({
    name: 'user',
    initialState,
    reducers: {
        setUserSettings: (state, action: PayloadAction<UserSettings>) => {
            state.userSettings = {
                ...state.userSettings,
                ...action.payload,
            };
        },
        setUserLocationSettings: (state, action: PayloadAction<string[]>): void => {
            state.userSettings['last-locations'] = action.payload;
        },
        setColorScheme: (state, action: PayloadAction<ColorSchemeValue>): void => {
            state.userSettings['color-scheme'] = action.payload;
        },
        setShowWorkload: (state, action: PayloadAction<boolean>): void => {
            state.userSettings['show-workload'] = action.payload.toString() as BooleanSetting;
        },
    },
});

export const { setUserSettings, setUserLocationSettings, setColorScheme, setShowWorkload } = userSlice.actions;

export default userSlice.reducer;
