import React, { useEffect, useMemo, useRef, useState } from 'react';
import { useAppSelector } from '../../../store';
import { AgGridReact } from '@ag-grid-community/react';
import { CellClassParams } from '@ag-grid-community/core';
import { ClientSideRowModelModule } from '@ag-grid-community/client-side-row-model';
import Button from 'react-bootstrap/Button';
import { Popover } from 'react-bootstrap';
import { AssignmentExpanded } from '../../model/Assignment';
import {
    AG_GRID_REACT_DEFAULT_PROPS,
    COLUMN_DEFAULTS,
    COMPACT_ROW_HEIGHT,
    createAtlasProfileLink,
    FIRST_CLICK_SORTS_DESCENDING,
    getVisibleRows,
} from '../../common';
import {
    AssignmentsByConsultantsModel,
    Cell,
    generateModelFromBackend,
    generateSummary,
    Row,
    Summary,
} from './AssignmentsByConsultantModel';
import './assignments.scss';
import { getBackgroundColorClass } from '../common/styling';
import { SafeProjectsSelector, useQueryOnlySafe } from '../common/SafeProjectsSelector';
import { PopoverTrigger } from '../common/PopoverTrigger';
import { ViewPeriods } from '../../common/ViewPeriod';
import { generateFilterFunction } from '../locations/LocationUtils';
import { generateGridStatePersistenceInstaller } from '../../common/gridState';
import { ProjectPotentialModel } from '../../common/ProjectPotentialModel';
import {
    bucketToColumn,
    columnDefs,
    getDisplayProjectName,
    getDisplayUtilization,
    GridContext,
    hasAssignmentsIn,
} from './AssignmentsByConsultantUtils';
import { generateGroupedBucketColumns } from '../../common/ColumnHelpers';
import CustomFilter from './CustomFilter';
import { useCurrentSchemeValue } from '../../customHooks/currentSchemeValue';
import { useShowWorkload } from '../../customHooks/showWorkload';
import { useCurrentLocations } from '../../customHooks/currentLocations';
import { useGlobalInterval } from '../../customHooks/globalInterval';
import { Link, useLocation } from 'react-router-dom';
import { TableContentOptions } from '../common/TableContentOptions';
import { useCurrentLocationIds } from '../../customHooks/currentLocationIds';

import { RootState } from '../../../store/setupStore';
import { useGetUtilizationQuery } from '../../../store/api/utilization';
import { useConsultantsExpanded } from '../../customHooks/consultants';
import { useAssignmentsExpanded } from '../../customHooks/assignments';
import { useDeleteAssignmentMutation } from '../../../store/api/assignments';
import { BillableProjectsSelector, useQueryOnlyBillable } from '../common/BillableProjectsSelector';

interface UtilizationCellClassParams extends CellClassParams {
    data: Row;
    value: Cell;
    context: GridContext;
}

const installGridStatePersister = generateGridStatePersistenceInstaller();

export function AssignmentsByConsultant(): React.JSX.Element {
    const agGrid = useRef<AgGridReact>(null);
    const [interval] = useGlobalInterval();
    const currentLocations = useCurrentLocations();
    const currentLocationIds = useCurrentLocationIds();

    const consultants = useConsultantsExpanded();
    const assignments = useAssignmentsExpanded();

    const projectPotentialModel = useMemo(() => {
        return new ProjectPotentialModel(assignments || []);
    }, [assignments]);

    const type = useAppSelector((state: RootState) => state.periodSelector.selectedPeriod);
    const [deleteAssignment] = useDeleteAssignmentMutation();
    const initialOnlySafe = useQueryOnlySafe();
    const [onlySafe, setOnlySafe] = useState(initialOnlySafe);
    const initialOnlyBillable = useQueryOnlyBillable();
    const [onlyBillable, setOnlyBillable] = useState(initialOnlyBillable);
    const { data: utilization } = useGetUtilizationQuery({ interval, type, locationIds: currentLocationIds });
    const showWorkload = useShowWorkload();

    const model = useMemo<AssignmentsByConsultantsModel | undefined>(() => {
        if (consultants && assignments && utilization) {
            const filterBySelectedLocation = generateFilterFunction(currentLocations);
            const filteredConsultants = consultants.filter(
                (c) =>
                    c.isEmployedBetween(interval) &&
                    filterBySelectedLocation(c) &&
                    (c.billability > 0 || hasAssignmentsIn(c, interval, assignments)),
            );
            const filteredAssignments = assignments
                .filter((a) => !onlySafe || a.isSafe())
                .filter((a) => !onlyBillable || !a.project?.nonBillable);
            return generateModelFromBackend(type, utilization, filteredConsultants, filteredAssignments, onlySafe);
        }
    }, [consultants, assignments, interval, type, onlySafe, onlyBillable, currentLocations, utilization]);

    const columnModel = useMemo(() => {
        if (!model) return [];

        const colGroupDefs = generateGroupedBucketColumns(model.columns, ViewPeriods[type].groupNameProvider, bucketToColumn);
        return [...columnDefs(showWorkload), ...colGroupDefs];
    }, [model, type, showWorkload]);

    const quickFilterText = useAppSelector((state: RootState) => state.filters.quickFilterText);
    const [summary, setSummary] = useState<Summary[]>();

    const calculateSummaryData = (
        assignments: AssignmentExpanded[] | undefined,
        model: AssignmentsByConsultantsModel | undefined,
        agGrid: React.RefObject<AgGridReact>,
        onlySafe: boolean,
        setSummary: (value: ((prevState: Summary[] | undefined) => Summary[] | undefined) | Summary[] | undefined) => void,
    ): void => {
        if (assignments && model) {
            const visibleConsultants = getVisibleRows<Row>(agGrid.current).map((r) => r.consultant);
            const filteredAssignments = assignments.filter((a) => !onlySafe || a.project?.isSafe());
            setSummary(generateSummary(model?.columns || [], visibleConsultants, filteredAssignments));
        }
    };

    useEffect((): void => {
        calculateSummaryData(assignments, model, agGrid, onlySafe, setSummary);
    }, [assignments, model, quickFilterText, onlySafe]);

    const [gridContext] = useState({} as GridContext);
    gridContext.confirmAndDeleteAssignment = async (a: AssignmentExpanded) => {
        if (confirm(`Wollen Sie wirklich den Einsatz für "${a.consultant?.name}" im Projekt "${a.project?.name}" entfernen?`)) {
            await deleteAssignment(a);
        }
    };

    gridContext.getProjectPotentialModel = () => {
        return projectPotentialModel;
    };

    return (
        <div className="grid-wrapper abc">
            <TableContentOptions
                creationButton={{ label: 'Neuer Einsatz', to: `/assignments/new` }}
                globalIntervalSelector
                viewPeriodSelector
                quickFilterField
            >
                <SafeProjectsSelector initialValue={initialOnlySafe} valueChanged={setOnlySafe} />
                <BillableProjectsSelector initialValue={initialOnlyBillable} valueChanged={setOnlyBillable} />
            </TableContentOptions>
            <div className="grid-body ag-theme-balham compact-rows">
                <AgGridReact
                    {...AG_GRID_REACT_DEFAULT_PROPS}
                    ref={agGrid}
                    context={gridContext}
                    columnDefs={columnModel}
                    defaultColDef={COLUMN_DEFAULTS}
                    rowData={model?.rows}
                    modules={[ClientSideRowModelModule]}
                    quickFilterText={quickFilterText}
                    enableBrowserTooltips={false}
                    tooltipShowDelay={500}
                    onFilterChanged={() => calculateSummaryData(assignments, model, agGrid, onlySafe, setSummary)}
                    components={{
                        summary: SummaryCell,
                        utilization: UtilizationCell,
                        customFilter: CustomFilter,
                        consultantActions: ConsultantActionButtons,
                    }}
                    pinnedBottomRowData={summary}
                    rowHeight={COMPACT_ROW_HEIGHT}
                    suppressRowTransform={true}
                    onGridReady={installGridStatePersister}
                    sortingOrder={FIRST_CLICK_SORTS_DESCENDING}
                />
            </div>
        </div>
    );
}

function SummaryCell(props: UtilizationCellClassParams): React.JSX.Element | null {
    if (!props.value) return null;
    const avgUtilization = props.value.utilization;
    const displayValue = isNaN(avgUtilization) ? '-' : `${avgUtilization.toFixed(0)}%`;

    return <div className="abc-util-summary">{displayValue}</div>;
}

function UtilizationCell(props: UtilizationCellClassParams): React.JSX.Element {
    const currentPath = useLocation().pathname;

    const row = props.data;
    const cell = props.value;

    const start = cell.period.start.toString();
    const projects = cell.assignments.map((a) => a.project);
    const potentialModel = props.context.getProjectPotentialModel();

    const utilizationRounded = Math.round(cell.utilization);
    const notEmployed = isNaN(cell.utilization) && !cell.assignments.length;
    const isAbsent = isNaN(cell.utilization) && !!cell.assignments.length;
    const supressValue = isNaN(cell.utilization) || utilizationRounded === 0 || utilizationRounded === 100;

    const utilityPercentageValue = !supressValue ? getDisplayUtilization(cell) : '';
    const effectiveUtilizationIdeal = isAbsent || utilizationRounded === 100;

    const currentSchemeValue = useCurrentSchemeValue();

    return notEmployed ? (
        <div className="abc-util-body not-employed" />
    ) : (
        <PopoverTrigger enabled={!!projects.length} popover={<UtilizationCellPopup {...props} />}>
            <div className={`abc-util-body ${cell.utilization ? 'has-utilization' : isAbsent ? 'absent' : 'has-warning'}`}>
                <div className="abc-util-projects">
                    {projects.length ? (
                        <>
                            {projects.slice(0, 4).map((project, index) => {
                                const probability: number = cell.assignments[index].getEffectiveProbabilityPercent();
                                const nonBillable: boolean = cell.assignments[index].project.nonBillable;
                                const bgColorClass = getBackgroundColorClass(
                                    probability,
                                    isAbsent,
                                    nonBillable,
                                    currentSchemeValue,
                                );
                                const absenceClass = project.absence ? 'absence' : '';
                                return (
                                    <span className={`abc-util-project w-100 ${bgColorClass} ${absenceClass}`} key={index}>
                                        {projects.length < 4 ? getDisplayProjectName(project, potentialModel, cell.period) : ''}
                                    </span>
                                );
                            })}
                        </>
                    ) : (
                        <Button
                            variant="link"
                            title="Einsatz erstellen"
                            as={Link as any}
                            to={`/assignments/new?consultant=${row.consultant.id}&start=${start}`}
                            state={{ returnTo: currentPath }}
                            className="ps-1"
                        >
                            <span className="im-plus ps-3" />
                        </Button>
                    )}
                </div>
                <div className="abc-util-percentage">
                    <span
                        className={`${effectiveUtilizationIdeal ? 'im-checkmark' : cell.utilization ? '' : 'im-error_outline'}`}
                    >
                        {utilityPercentageValue}
                    </span>
                </div>
            </div>
        </PopoverTrigger>
    );
}

function UtilizationCellPopup(props: UtilizationCellClassParams): React.JSX.Element {
    const currentPath = useLocation().pathname;

    const row = props.data;
    const consultantId = row.consultant.id;

    const cell = props.value;
    const start = cell.period.start.toString();

    return (
        <div>
            {cell.assignments.map((a) => (
                <AssignmentRow key={a.id} assignment={a} cell={cell} context={props.context} />
            ))}
            <Popover.Body className="py-2 text-center">
                <Button
                    className="btn-sm"
                    title="Neuen Einsatz erstellen"
                    as={Link as any}
                    to={`/assignments/new?consultant=${consultantId}&start=${start}`}
                    state={{ returnTo: currentPath }}
                >
                    <span className="oi oi-plus" /> Neuer Einsatz
                </Button>
            </Popover.Body>
        </div>
    );
}

function AssignmentRow(props: { cell: Cell; assignment: AssignmentExpanded; context: GridContext }): React.JSX.Element {
    return (
        <div>
            <Popover.Header as="h3" className="pb-0">
                <AssignmentPopupHead {...props} />
            </Popover.Header>
            <Popover.Body className="py-0">
                <AssignmentPopupBody {...props} />
            </Popover.Body>
        </div>
    );
}

function AssignmentPopupHead(props: { cell: Cell; assignment: AssignmentExpanded; context: GridContext }): React.JSX.Element {
    const currentPath = useLocation().pathname;
    const a = props.assignment;
    const cell = props.cell;
    const potentialModel = props.context.getProjectPotentialModel();

    return (
        <div className="d-flex">
            <div className="flex-grow-1">
                <div>{getDisplayProjectName(a.project, potentialModel, cell.period)}</div>
                <div className="account-manager" title="Ansprechpartner">
                    {a?.project?.accountManager?.name}
                </div>
            </div>
            <div className="options ms-2 text-right">
                <Button
                    variant="link"
                    title="Projekt bearbeiten"
                    as={Link as any}
                    to={`/projects/${a?.project?.id}`}
                    state={{ returnTo: currentPath }}
                    className="p-0"
                >
                    <span className="oi oi-pencil" />
                </Button>
            </div>
        </div>
    );
}

function AssignmentPopupBody(props: { cell: Cell; assignment: AssignmentExpanded; context: GridContext }): React.JSX.Element {
    const currentPath = useLocation().pathname;
    const a = props.assignment;
    const cell = props.cell;

    function getProgressStyle(mStart: number, mEnd: number, pStart: number, pEnd: number): Record<string, string> {
        const pStartCorrected = pStart < mStart ? mStart : pStart; // project start min month start
        const pEndCorrected = pEnd > mEnd ? mEnd : pEnd; // project end max month end
        const mRange = mEnd - mStart;
        const pRange = pEndCorrected - pStartCorrected;
        const startOffset = pStartCorrected - mStart;
        const w = (100 * pRange) / mRange;
        const l = (100 * startOffset) / mRange;
        const lPercent = l < 0 ? 0 : l;
        const wPercent = w > 100 ? 100 : w;

        return { width: `${wPercent}%`, left: `${lPercent}%` };
    }

    const monthStartDateTime = new Date(cell.period.start.toString()).getTime();
    const monthEndDateTime = new Date(cell.period.end.toString()).getTime();

    const projectInterval = a.getEffectiveDuration();
    const projectStartDateTime = new Date(projectInterval.start.toString()).getTime();
    const projectEndDateTime = new Date(projectInterval.end.toString()).getTime();
    const projectPotentialUsage = props.context.getProjectPotentialModel().calculateUsage(a.project, cell.period);

    return (
        <div className="mb-1">
            <div className="d-flex pt-1">
                <div className="title flex-grow-1">
                    <span className="ps-2 pe-3" title="Projektwahrscheinlichkeit">
                        W<sub>P</sub>: {a.project?.probabilityPercent}%
                    </span>
                    {projectPotentialUsage && (
                        <span className="pe-2" title="Projektpotential">
                            P: {projectPotentialUsage.usageText}
                        </span>
                    )}
                </div>
            </div>
            <div className="d-flex pt-1">
                <div className="flex-grow-1">
                    <span className="ps-2 pe-3" title="Einsatzwahrscheinlichkeit">
                        W<sub>E</sub>: {a.probabilityPercent}%
                    </span>
                    <span className="pe-2" title="Auslastung">
                        A: {a.project.nonBillable ? 'nicht abrechenbar' : a.utilization + '%'}
                    </span>
                </div>
                <div className="options ms-2 text-right">
                    <Button
                        variant="link"
                        title="Einsatz bearbeiten"
                        as={Link as any}
                        to={`/assignments/${a.id}`}
                        state={{ returnTo: currentPath }}
                        className="py-0 px-1"
                    >
                        <span className="oi oi-pencil" />
                    </Button>
                    <Button
                        variant="link"
                        title="Einsatz löschen"
                        className="py-0 px-0"
                        onClick={() => props.context.confirmAndDeleteAssignment(a)}
                    >
                        <span className="oi oi-trash" />
                    </Button>
                </div>
            </div>
            <div
                style={getProgressStyle(monthStartDateTime, monthEndDateTime, projectStartDateTime, projectEndDateTime)}
                className={`progressbar ${a.project!.absence ? 'absence' : ''}`}
            />
        </div>
    );
}

function ConsultantActionButtons(props: CellClassParams): React.JSX.Element | undefined {
    const href = createAtlasProfileLink(props.data.consultant);

    return (
        <>
            {href && (
                <a href={href} target="_blank" rel="noopener noreferrer" title={'Profilseite in Atlas öffnen'}>
                    <span className="details-atlas" />
                </a>
            )}
        </>
    );
}
