import { Assignment, AssignmentImpl } from '../../model/Assignment';
import { toEmptyStr } from '../common/form';
import { ConsultantExpanded } from '../../model/Consultant';
import { LocalDate } from '@js-joda/core';
import { AssignmentForm } from './AssignmentsForm';
import { ProjectExpanded } from '../../model/Project';
import { formatLocalDate } from '../../common';

export function assignmentToFormValues(assignment?: Assignment): AssignmentForm | undefined {
    if (assignment) {
        return {
            projectId: toEmptyStr(assignment.projectId),
            consultantId: toEmptyStr(assignment.consultantId),
            start: toEmptyStr(assignment.start),
            end: toEmptyStr(assignment.end),
            utilization: toEmptyStr(assignment.utilization),
            probabilityPercent: toEmptyStr(assignment.probabilityPercent),
            projectName: toEmptyStr(null),
        };
    }
}

export function formValuesToAssignment(formValues: AssignmentForm, defaultValues: Assignment): Assignment {
    const { id } = defaultValues;
    return new AssignmentImpl(
        Object.assign(
            {
                start: formValues.start,
                end: formValues.end,
                utilization: formValues.utilization as number,
                consultantId: formValues.consultantId,
                projectId: formValues.projectId,
                probabilityPercent: formValues.probabilityPercent as number,
            },
            { id },
        ),
    );
}

export const filterInactiveConsultants = (consultant: ConsultantExpanded): boolean => !consultant.hasLeftAt(LocalDate.now());

export function longProjectNameFormatter(project: ProjectExpanded): string {
    const projDuration = project.getDuration();
    const accountManagerStr = project?.accountManager ? `, AP: ${project.accountManager.name}` : '';
    return `${project.name}  (${formatLocalDate(projDuration.start)} - ${formatLocalDate(projDuration.end)}${accountManagerStr})`;
}

export function consultantNameFormatter(consultant: ConsultantExpanded): string {
    return `${consultant.name} (${consultant.location?.name})`;
}
export function createInitialAssignmentValues(params: {
    consultantId: string | null;
    projectId: string | null;
    start: string | null;
}): AssignmentImpl {
    return new AssignmentImpl({
        id: '',
        consultantId: params.consultantId || '',
        projectId: params.projectId || '',
        start: params.start || '',
        utilization: 100,
        probabilityPercent: 100,
    });
}
export function isProjectInSameMonthAsAssignment(start: string, end: string, assignmentStart: string): boolean {
    const assignmentStartDate = LocalDate.parse(assignmentStart);
    const endOfMonth = assignmentStartDate.withDayOfMonth(assignmentStartDate.lengthOfMonth());
    const projectStart = LocalDate.parse(start);
    const projectEnd = LocalDate.parse(end);

    const isProjectRunningInAssignmentStartMonth = !projectStart.isAfter(endOfMonth);
    const projectHasNotEnded = !assignmentStartDate.isAfter(projectEnd);

    return isProjectRunningInAssignmentStartMonth && projectHasNotEnded;
}

export const filterProjectsOutOfAssignmentSpan = (assignmentStart: string | null | undefined) => (project: ProjectExpanded) => {
    if (!assignmentStart) {
        return LocalDate.now().toString() <= project.end;
    }
    return isProjectInSameMonthAsAssignment(project.start, project.end, assignmentStart);
};
