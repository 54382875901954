import React from 'react';
import { AdministrationSubnavigation } from './AdministrationSubnavigation';
import { Changes } from '../changes/Changes';
import { UnknownRoute } from '../common/UnknownRoute';
import { Import } from '../import/Import';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ConsultantsOverview } from '../consultants/ConsultantsOverview';
import { ConsultantsForm } from '../consultants/ConsultantsForm';
import { LocationsOverview } from '../locations/LocationsOverview';
import { LocationsForm } from '../locations/LocationsForm';
import { Export } from '../export/Export';

export function Administration(): React.JSX.Element {
    return (
        <>
            <AdministrationSubnavigation />
            <Routes>
                <Route index element={<Navigate to="consultants" replace />} />
                {/*/!* Here will be a detail page later.*/}
                {/*        <AdministrationNotifications />*/}
                {/*    *!/*/}
                <Route path="consultants">
                    <Route index element={<ConsultantsOverview />} />
                    <Route path=":consultantArg" element={<ConsultantsForm parentPath={`/administrative/consultants`} />} />
                </Route>
                <Route path="locations">
                    <Route index element={<LocationsOverview />} />
                    <Route path=":locationArg" element={<LocationsForm parentPath={`/administrative/locations`} />} />
                </Route>
                <Route path="changelog" element={<Changes />} />
                <Route path="import" element={<Import />} />
                <Route path="export" element={<Export />} />
                <Route path="*" element={<UnknownRoute />} />
            </Routes>
        </>
    );
}
