import React from 'react';

interface JiraImportHintProps {
    edited: boolean;
}

function JiraImportHint(props: JiraImportHintProps): React.JSX.Element {
    return (
        <div
            className="alert alert-warning position-absolute"
            style={{ marginLeft: '100%', width: '200px' }}
            hidden={!props.edited}
        >
            Achtung, alle Felder außer Reisebereitschaft und Stichwörter werden aus Jira importiert. Änderungen an anderen Feldern
            werden durch den nächsten Import überschrieben. Die Zuordnung findet über die E-Mail-Adresse statt. Weitere Details{' '}
            <a
                target={'_blank'}
                href={'https://confluence.codecentric.de/display/SPACE/SPACE+FAQ#SPACEFAQ-Jira-Import'}
                rel="noreferrer"
            >
                hier
            </a>
            .
            <br />
            <br />
            Neue Consultants können hier bereits angelegt werden bevor diese in Jira verfügbar sind.
        </div>
    );
}

export default JiraImportHint;
