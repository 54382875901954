import { DATE_PATTERN, DatePeriod, intersect } from '../../common';
import { ColDef, GetQuickFilterTextParams, ValueGetterParams } from '@ag-grid-community/core';
import { Bucket, Cell } from './AssignmentsByConsultantModel';
import { AssignmentExpanded } from '../../model/Assignment';
import { ConsultantExpanded } from '../../model/Consultant';
import { ProjectExpanded } from '../../model/Project';
import { ProjectPotentialModel } from '../../common/ProjectPotentialModel';

export interface GridContext {
    confirmAndDeleteAssignment: (a: AssignmentExpanded) => void;
    getProjectPotentialModel: () => ProjectPotentialModel;
}

export function columnDefs(showWorkload: boolean): ColDef[] {
    return [
        {
            headerName: 'Consultant',
            colId: 'name',
            width: 240,
            pinned: true,
            valueGetter: (row) => (row.data.isSummary ? row.data.name : row.data.consultant.name),
            tooltipValueGetter: (row) => (row.data.isSummary ? row.data.tooltip : undefined),
            getQuickFilterText: consultantQuickFilterValueGetter(),
        },
        {
            headerName: 'h/W',
            colId: 'workload',
            width: 80,
            pinned: true,
            field: 'consultant.workload',
            hide: !showWorkload,
        },
        {
            colId: 'actions',
            width: 44,
            pinned: true,
            filter: false,
            sortable: false,
            suppressNavigable: true,
            cellRendererSelector: (row) => (row.data.isSummary ? {} : { component: 'consultantActions' }),
        },
        { headerName: 'Standort', field: 'consultant.location.name', width: 120 },
        {
            headerName: 'Region',
            valueGetter: (row) => (row.data.isSummary ? null : row.data.consultant.location.region),
            hide: true,
        },
    ];
}

export function bucketToColumn(bucket: Bucket): ColDef {
    return {
        colId: bucket.start + bucket.name,
        headerName: bucket.name,
        filter: 'customFilter',
        headerTooltip: `${bucket.start.format(DATE_PATTERN)} - ${bucket.end.format(DATE_PATTERN)}`,
        cellClass: 'abc-util',
        width: 200,
        comparator: cellComparator,
        cellRendererSelector: (params) => (params.data.isSummary ? { component: 'summary' } : { component: 'utilization' }),
        valueGetter: cellValueGetter(bucket),
        getQuickFilterText: quickFilterValueGetter(bucket),
    };
}

function cellComparator(a: Cell, b: Cell): number {
    return (a.sortValue || '').localeCompare(b.sortValue || '');
}

function cellValueGetter(bucket: Bucket): (p: ValueGetterParams | GetQuickFilterTextParams) => Cell {
    return (row: ValueGetterParams | GetQuickFilterTextParams) => {
        const cells = row?.data?.cells;
        return cells ? cells[bucket.index] : undefined;
    };
}

function quickFilterValueGetter(bucket: Bucket): (p: GetQuickFilterTextParams) => string {
    const getCellValue = cellValueGetter(bucket);
    return (p) => getFilterString(getCellValue(p));
}

function consultantQuickFilterValueGetter(): (p: GetQuickFilterTextParams) => string {
    return (p) => (p.data.isSummary ? '' : p.data.consultant.name + ' ' + p.data.consultant.keywords);
}

function getFilterString(c: Cell): string {
    return c?.assignments?.map((a: AssignmentExpanded) => a.project?.name).join(', ');
}

export function getDisplayUtilization(cell: Cell): string {
    return !isNaN(cell.utilization) ? `${cell.utilization.toFixed(0)}%` : '';
}

export function getDisplayProjectName(project: ProjectExpanded, model: ProjectPotentialModel, interval: DatePeriod): string {
    const potential = model.calculateUsage(project, interval);
    if (potential && potential.isMismatched) {
        return '! ' + project.name;
    }
    return project.name;
}

export function hasAssignmentsIn(c: ConsultantExpanded, interval: DatePeriod, assignments: AssignmentExpanded[]): boolean {
    return !!assignments.find((a) => a.consultant.id === c.id && intersect(interval, a.getEffectiveDuration()));
}
