import React, { useEffect, useMemo, useState } from 'react';
import { CategoryScale, Chart, ChartData, ChartOptions, Legend, LinearScale, LineElement, PointElement } from 'chart.js';
import { Line } from 'react-chartjs-2';
import { getHistoryForMonths } from './HistoryApi';
import { CalculatedValuesForMonth, HistoryForMonth } from './HistoryForMonth';
import { ChartDataset } from 'chart.js/dist/types';
import { SelectorFormField } from '../common/SelectorFormField';
import { Formik } from 'formik';
import { Form } from 'react-bootstrap';
import { useCurrentLocations } from '../../customHooks/currentLocations';

Chart.register(CategoryScale, LinearScale, PointElement, LineElement, Legend);

interface HistoryForm {
    months: string[];
    metric: Metric;
}

type Metric = 'utilization' | 'weightedUtilization' | 'safeUtilization';

function convertHistoryToChart(histories: HistoryForMonth[], selectedMetric: Metric): ChartData<'line'> {
    function adjustToTimeRange(history: CalculatedValuesForMonth[]): CalculatedValuesForMonth[] {
        const allMonths: CalculatedValuesForMonth[] = new Array(13).fill(null);
        history.forEach((monthValues) => {
            // -12 -11 -10 -9 -8 -7 -6 -5 -4 -3 -2 -1 0  <- relativeToTargetMonth
            // 0   1   2   3  4  5  6  7  8  9 10 11 12  <- index
            allMonths[monthValues.relativeToTargetMonth + 12] = monthValues;
        });
        return allMonths;
    }

    function toDataset(historyForMonth: HistoryForMonth): ChartDataset<'line'> {
        const shiftedData: CalculatedValuesForMonth[] = adjustToTimeRange(historyForMonth.history);
        const color = colorHash(historyForMonth.targetMonth);
        return {
            label: historyForMonth.targetMonth,
            borderColor: color,
            backgroundColor: color,
            data: shiftedData.map((monthValues) => (monthValues ? monthValues[selectedMetric] : null)),
        };
    }

    function colorHash(input: string): string {
        let sum: number = 0;

        for (let i = 0; i < input.length; i++) {
            sum += input.charCodeAt(i);
        }

        const r = Math.floor((0.5 + Math.sin(sum + 1) / 2) * 256);
        const g = Math.floor((0.5 + Math.sin(sum + 2) / 2) * 256);
        const b = Math.floor((0.5 + Math.sin(sum + 3) / 2) * 256);

        return `rgb(${r}, ${g}, ${b})`;
    }

    const datasets = histories.map((historyForMonth) => toDataset(historyForMonth));

    const labels = ['-12', '-11', '-10', '-9', '-8', '-7', '-6', '-5', '-4', '-3', '-2', '-1', '0'];

    return {
        labels: labels,
        datasets: datasets,
    };
}

export function History(): React.JSX.Element {
    const [histories, setHistories] = useState<HistoryForMonth[]>([]);
    const [selectedMonths, setSelectedMonths] = useState<string[]>([]);
    const [selectedMetric, setSelectedMetric] = useState<Metric>('utilization');
    const currentLocations = useCurrentLocations();

    const availableMonths = [
        '2024-02',
        '2024-03',
        '2024-04',
        '2024-05',
        '2024-06',
        '2024-07',
        '2024-08',
        '2024-09',
        '2024-10',
        '2024-11',
        '2024-12',
        '2025-01',
        '2025-02',
        '2025-03',
        '2025-04',
        '2025-05',
        '2025-06',
    ]; // TODO fetch from api

    const availableMetrics: { label: string; metricName: Metric }[] = [
        { label: 'Auslastung', metricName: 'utilization' },
        { label: 'Gewichtete Auslastung', metricName: 'weightedUtilization' },
        { label: 'Sichere Auslastung', metricName: 'safeUtilization' },
    ];

    const chartoptions: ChartOptions<'line'> = {
        scales: {
            x: {
                title: {
                    display: true,
                    text: 'Monate vor Zielmonat',
                },
            },
            y: {
                title: {
                    display: true,
                    text: 'berechnete Auslastung in %',
                },
                min: 0,
                max: 100,
            },
        },
    };

    useEffect(() => {
        if (selectedMonths.length != 0) {
            getHistoryForMonths(selectedMonths, currentLocations).then((res) => setHistories(res));
        }
    }, [selectedMonths, currentLocations]);

    const chartData: ChartData<'line'> = useMemo(
        () => convertHistoryToChart(histories, selectedMetric),
        [histories, selectedMetric],
    );

    function onSubmit(values: HistoryForm): void {
        setSelectedMonths(values.months);
        setSelectedMetric(values.metric);
    }

    return (
        <div className={'mx-auto col-lg-6 mt-3'}>
            <div>
                <p>
                    Diese Seite ist eine Demo, damit ihr euch besser vorstellen könnt, welche Auswertungen der historischen Daten
                    möglich sein könnten. Wir versuchen eure Wünsche hier schnell unterzubringen, sodass wir gemeinsam
                    ausprobieren was euch hilft.
                </p>
                <p>Die Daten werden seit Februar 2024 gesammelt.</p>
                <p>
                    Hier ein Beispiel-Diagramm mit der Vorhersage für die Auslastung der ausgewählten Monate im Verlauf der Zeit.
                </p>
            </div>
            <div>
                <Formik onSubmit={onSubmit} initialValues={{ months: [], metric: 'utilization' }}>
                    {(props) => (
                        <Form onSubmit={props.handleSubmit} noValidate>
                            <SelectorFormField
                                name="months"
                                label="Zielmonate"
                                data={availableMonths}
                                sortBy="label"
                                isMulti={true}
                                labelFormatter={(x) => x}
                                valueSelector={(x) => x}
                                onChange={props.submitForm}
                            />
                            <SelectorFormField
                                name="metric"
                                label="Wert"
                                data={availableMetrics}
                                sortBy="label"
                                labelFormatter={(x) => x.label}
                                valueSelector={(x) => x.metricName}
                                onChange={props.submitForm}
                            />
                        </Form>
                    )}
                </Formik>
            </div>
            <div style={{ maxHeight: '500px', maxWidth: '500px' }}>
                {chartData && <Line data={chartData} options={chartoptions} />}
            </div>
        </div>
    );
}
